import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Header from './components/Header';
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import ResumeSection from './components/ResumeSection';
import ViewFullCV from './components/ViewFullCV';
import MySkillsSection from './components/MySkillsSection';
import MyProjectsSection from './components/MyProjectsSection';
import HireMeSection from './components/HireMeSection';
import LinksSection from './components/LinksSection';
import ContactMeSection from './components/ContactMeSection';
import ContactFormSection from './components/ContactFormSection';
import Footer from './components/Footer';
// ========================================
import Maps from './routes/Maps';


const data = require('./data/cv.json');

// {/* <Route path="/" element={<HomeSection />} />
// <Route path="/about" element={<AboutSection data={data} />} />
// <Route path="/resume" element={<ResumeSection data={data} />} />
// <Route path="/skills" element={<MySkillsSection data={data} />} />
// <Route path="/projects" element={<MyProjectsSection data={data} />} />
// <Route path="/hireme" element={<HireMeSection data={data} />} />
// <Route path="/contact" element={<ContactMeSection />} /> */}
function App() {
  return (
      <div className="app">
        <Routes>
          <Route path="/" element={<>
            <Header name={data.me.name}/>
            <HomeSection />
            <AboutSection data={data} />
            <ResumeSection data={data} />
            <ViewFullCV data={data} />
            <MySkillsSection data={data} />
            <MyProjectsSection data={data} />
            <HireMeSection data={data} />
            <LinksSection data={data} />
            <ContactMeSection />
            <ContactFormSection />
            <Footer />
          </>} />
          <Route path="/maps" element={<Maps />} />
        </Routes>
      </div>
  );
}

export default App;