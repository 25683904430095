import './ContactMeSection.css';

export default function ContactMeSection() {
    return (
        <div className="contactMeSection">
            <div className="container">
                <h2>Contact Me</h2>
            </div>
        </div>
    );
}