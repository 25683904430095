import './LinksSection.css';

export default function LinksSection({data}) {
    return (
        <div className="linksSection">
            <div className="container">
                <h2>Links</h2>
                <div className="links-section-content">
                    <a href={data.links.github} target="_blank" rel="noopener noreferrer">Github</a>
                    <a href={data.links.linkedin} target="_blank" rel="noopener noreferrer">LinkedIn</a>
                    <a href={data.links.email} target="_blank" rel="noopener noreferrer">Email</a>
                </div>
            </div>
        </div>
    );
}