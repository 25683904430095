import './Header.css';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Header({ name }) {
    return (
        <header className="header">
            <Stack className='container' direction='row' justifyContent='space-between' alignItems='center' >
                <h1 className='logo'> {name.toUpperCase() } </h1>
                <Stack className='nav' direction='row' justifyContent='center' spacing={4} alignItems='center'>
                    <Link className='link active' to='/'>Home</Link>
                    <Link className='link' to='#about'>About</Link>
                    <Link className='link' to='#resume'>Resume</Link>
                    <Link className='link' to='#skills'>Skills</Link>
                    <Link className='link' to='#projects'>Projects</Link>
                    <Link className='link' to='#links'>Links</Link>
                    <Link className='link' to='#contact'>Contact</Link>
                    
                </Stack>

            </Stack>
        </header>
    );
}