import './AboutSection.css';
import { Stack } from '@mui/material';
import CountUp from 'react-countup';
export default function AboutSection({data}) {
    return (
        <section className="aboutSection">
            <Stack className='container' direction='row'>
                <div className='about-section-left'>
                    <img src='https://ahmedkira.com/images/bg_1.png' alt='avatar'/>
                </div>
                <Stack className='about-section-right' spacing={4} justifyContent='center'>
                    <h2>About Me</h2>
                    <p> {data.about_me} </p>
                    <Stack className='info' direction='column' spacing={2}>
                        <div className='line'><h4>Name: </h4>            <span>{data.me.name}</span></div>
                        <div className='line'><h4>Date of birth: </h4>   <span>{data.me.dob}</span></div>
                        <div className='line'><h4>Address: </h4>         <span>{data.me.address}</span></div>
                        <div className='line'><h4>Email: </h4>           <span>{data.me.email}</span></div>
                        <div className='line'><h4>Phone: </h4>           <span>{data.me.phone}</span></div>
                    </Stack>
                    <div className='projectCount'><CountUp className='no' start={0} end={120} duration={2} /> Project complete</div>
                    <a href='https://beta.ahmedkira.com/files/pdf/Ahmed%20Kira%20-%20Software%20Developer.pdf' className='download-cv-btn'>Download CV</a>
                </Stack>
            </Stack>
        </section>
    );
}