import './ViewFullCV.css';
import { Stack } from '@mui/material';

export default function ViewFullCV({data}) {
    return (
        <section className="viewFullCV">
            <Stack className='container' spacing={4} justifyContent='center' alignItems='center'>
                <a href='https://beta.ahmedkira.com/files/pdf/Ahmed%20Kira%20-%20Software%20Developer.pdf' className='download-cv-btn'>View Full CV</a>
            </Stack>
        </section>
    );
}