import './Maps.css';
// marker cluster
// import { useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';
import { MapContainer, 
    TileLayer,
    // useMap,
    Marker, 
    Popup,
} from 'react-leaflet'

import mapDataArr from '../data/map.json'

import L from 'leaflet'
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow
});
L.Marker.prototype.options.icon = DefaultIcon;


// import MarkerClusterGroup from 'react-leaflet-markercluster'


const centerPosition = [30.03833650017866, 31.212965548038483]


export default function Maps() {

    let xMap = mapDataArr.features.map((mark)=>{
        if(mark.geometry.coordinates[0] === 0 && mark.geometry.coordinates[1] === 0){
            
            let points = (new URL(mark.properties.google_maps_url).searchParams).get('q').split(',').map((point)=>parseFloat(point))

            // return (points.length===2)?points:undefined;
            if(points.length===2){
                return {point: points,google_maps_url: mark.properties.google_maps_url };
            } else {
                // console.error(`${points}`)
                return {point: undefined,google_maps_url: mark.properties.google_maps_url };
            }

        } else {
            return {
                point: mark.geometry.coordinates,
                google_maps_url: mark.properties.google_maps_url
            }
        }
        
    })

    xMap = xMap.filter( e => e.point !== undefined )

    console.log(xMap)

    const map_access_token = 'pk.eyJ1IjoiYWdhbGFsOTIiLCJhIjoiY2xjb29xYm9pNnl5bzNzbGt5Z2xzeHMxOSJ9.FKIKFLJr1DftxLnMdC_bkQ';
    const MAPBOX_MAP_URL = `https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${map_access_token}`
    // const OPENSTREETMAP_MAP_URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    // const MAPBOX_TILE_ID = 'mapbox/streets-v11'

    const MAP_URL = MAPBOX_MAP_URL




    return (
        <div className="maps">
            {/* transform: translate3d(0, 0, 0); */}
            {/* style={{ transform: "translate3d(0, 0, 0)" }} */}
            <div className="container">
                {/* <h2>My maps</h2> */}
                <MapContainer center={centerPosition} zoom={8} scrollWheelZoom={true} style={{ height: "100vh" }}>
                    <TileLayer
                        attribution=''
                        url={MAP_URL}
                        id='mapbox/streets-v11'
                    />
                    {xMap.length>1 && xMap.map((position, idx) => (
                        <Marker key={`marker-${idx}`} position={position.point}>
                            <Popup>
                                Nice Point: <a href={position.google_maps_url}>google_maps_url</a>
                                <br />
                                {JSON.stringify(position.point)}
                            </Popup>
                        </Marker>
                    ))}
                    
                </MapContainer>
            </div>
        </div>
    );
}