import './ResumeSection.css';
import { Stack } from '@mui/material';
import ResumeCard from './ResumeCard';

export default function ResumeSection({data}){
    return (
        <section className="resumeSection">
            <div className="container">
                <h2>Resume</h2>
                <p className='quote'>{data.resume_quote}</p>
                <Stack className='resume' direction='row' justifyContent='center' alignItems='center' spacing={0} flexWrap='wrap'>
                    <ResumeCard data={data.main.all[0]} />
                    <ResumeCard data={data.main.all[1]} />
                    <ResumeCard data={data.main.all[2]} />
                    <ResumeCard data={data.main.all[3]} />
                    <ResumeCard data={data.main.all[4]} />
                </Stack>
            </div>
        </section>
    );
}