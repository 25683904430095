import './HomeSection.css';
// import { Stack } from '@mui/material';
export default function HomeSection() {
    return (
        <section className="homeSection">
            <div className="container">
                {/* center */}
                {/* <Stack direction="row" spacing={2} justifyContent="center" alignItems="center"> */}
                    <div className="left">
                        <h1>HELLO!</h1>
                        <h1>I'm <span className="name">John Doe</span></h1>
                        <h2>Software Engineer & Web Developer</h2>
                    </div>
                    <div className="right">
                        {/* <img src="https://i.imgur.com/1ZQ2Z1C.png" alt="John Doe" /> */}
                        {/* .
                         */}
                    <img src='https://ahmedkira.com/images/bg_1.png' alt='avatar'/>

                    </div>
                {/* </Stack> */}
            {/* <h2>Home</h2> */}
            </div>
        </section>
    );
}