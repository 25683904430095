import './MySkillsSection.css';

import { Stack, /* LinearProgress */ } from '@mui/material';

function SkillCard({data}) {
    return (
        <div className="skillCard">
            <div className="skillCard-title">{data.title}</div>
            {/* <div className="skillCard-percent">{data.percent}</div> */}
            {/* <LinearProgress variant="determinate" value={data.percent} /> */}
        </div>
    );
}

export default function MySkillsSection({data}) {
    return (
        <section className="mySkillsSection">
            <div className="container">
                <h2>My Skills</h2>
                <p className='quote'>{data.skills.quote}</p>
                <Stack className='skills' direction='row' justifyContent='center' alignItems='center' spacing={0} flexWrap='wrap'>
                    {data.skills.all.map(skill => <SkillCard key={skill.id} data={skill} />)}
                </Stack>
            </div>
        </section>
    );
}