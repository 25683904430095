import './HireMeSection.css';

export default function HireMeSection({data}) {
    return (
        <div className="hireMeSection">
            <div className="container">
                <h2>Hire Me</h2>
            </div>
        </div>
    );
}