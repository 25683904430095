import './MyProjectsSection.css';

export default function MyProjectsSection({data}) {
  return (
    <div className="myProjectsSection">
      <div className="container">
        <h2>My Projects</h2>
        <p className='quote'>{data.resume_quote}</p>
        <div className="my-projects-section-content"></div>
      </div>
    </div>
    );
}