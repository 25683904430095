import './Footer.css';
import { Link } from 'react-router-dom';

export default function Footer() {
    return (
        <div className="footer">
            <div className="container">
                <h2>Footer</h2>
                {/* /maps */}
                <Link className='' to='/maps'>Maps</Link>
            </div>
        </div>
    );
}