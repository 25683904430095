import './ResumeCard.css';

export default function ResumeCard({data}){
    // name | company | date | info
    return (
        <div className="resumeCard">
            <span className='date'>{data.date}</span>
            <h5 className='name'>{data.name}</h5>
            <span className='company'>{data.company}</span>
            <p className='info'>{data.info}</p>

        </div>
    );
}